import logo from './logo.svg';
import './App.css';
import { Amplify, Storage, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Home from './Home'
import Opportunities from './pages/Opportunities';
import Deals from './pages/Deals'
import InvCompanyProfile from './pages/InvCompanyProfile';
import FundTracker from './pages/FundTracker';
import Container from 'react-bootstrap/Container';
import { Nav, NavItem } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Footer from './Footer'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react'
import InvestorProfile from './pages/InvestorProfile'
import Dataroom from './pages/Dataroom';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
import { View, Image } from '@aws-amplify/ui-react';
import { NavLink } from "react-router-dom";

Amplify.configure(awsconfig);


function RequireAuth({ children }) {
  const [roleA, setUserRoleA] = useState('')
  const [waitT, setwaitT] = useState(true)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(res => {
      setUserRoleA(res.attributes['custom:role'])
      setwaitT(false)
    })
  })
  return 'super_admin' == roleA ? children : waitT == true ? <div>Loading....</div> : <Navigate to="/" replace />
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HeaderDispl><Home /></HeaderDispl>,
  }, {
    path: "/invprofile",
    element: <HeaderDispl><InvestorProfile /></HeaderDispl>
  }
]);


async function onSignOutClick() {
  await Auth.signOut()
    .then(data => {
      window.location = '/'
    })
    .catch(err => {
      console.log(err)
    });
}



function App({ signOut, user }) {

  return (
    <RouterProvider router={router} />
  );
}

function HeaderDispl({ children }) {
  const [userInfo, setUserInfo] = useState('')
  const [role, setUserRole] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    Auth.currentAuthenticatedUser().then(res => {
      setUserInfo(res.attributes['email']);
      setUserRole(res.attributes['custom:role'])
      setLoading(false)
    })
  })
  return (
    <Container className=''>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">MLC INVESTOR PORTAL</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{ justifyContent: 'flex-end' }} className="container-fluid" >
              <Nav.Item className="ml-auto" eventKey={5} >
                <Nav.Link><NavLink to='/' style={{
                  textDecoration: 'none', color: 'white', cursor: 'pointer'
                }}>Documents</NavLink></Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml-auto" eventKey={6} >
                <Nav.Link><NavLink to='/invprofile' style={{
                  textDecoration: 'none', color: 'white', cursor: 'pointer'
                }}>My Profile</NavLink></Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml-auto" eventKey={7}> <Nav.Link onClick={() => onSignOutClick()}>Sign Out</Nav.Link></Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className='c1' style={{ paddingTop: '20px' }} >
        <Row>
          {children}
        </Row>
      </Container>
    </Container>
  )
}

function MainApp() {
  const components = {
    Header() {
      return (
        <View textAlign="center">
          <br />
          <Image
            alt="MLC LOGO"
            src="https://static.wixstatic.com/media/a25bb2_aba1b1e337f74328ab6d0206c5a60ff9~mv2.png/v1/fill/w_90,h_80,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Site%20Logo.png"
          />
          <div><b>First Time Login: </b>
          <i>Please use the forgot password link to request a new password to your registered email id. 
          Your email id needs to be registered with Madison Lake Capital. For help in logging in please email 
          <a href='mailto:info@madisonlakecapital.com'> info@madisonlakecapital.com</a></i></div>
          <br />
        </View>
      );
    },
  }
  return (
    <Authenticator components={components} hideSignUp={true}>
      <App />
    </Authenticator>
  )
}

export default MainApp;
//export default withAuthenticator(App, { hideSignUp: true });