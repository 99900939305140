import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner';
import UserRoom from '../forms/UserRoom';
import Modal from 'react-bootstrap/Modal';

function renderAcrodianItem(children) {
    var at = '/';
    const result2 = children.map((a, index) => {
        if (a.name.indexOf('.') == -1) {
            return <Accordion.Item className='folder' eventKey={() => this.getKey()} onClick={() => this.ccLLL(index, a.level)}>
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={1}><Image src='./folder.png'></Image></Col>
                            <Col>{a.name}</Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    {renderAcrodianItem(a.children)}
                </Accordion.Body>
            </Accordion.Item>
        } else {
            return <div onClick={() => this.download(this.generateKeyPath(a.loc), a.name)}>
                <Card className='file' style={{ border: 'none', cursor: 'pointer' }}>
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col xs={1}><Image src='./pdf.gif'></Image></Col>
                                <Col>{a.name}</Col>
                                <Col><button
                                    type="button" onClick={(e) => e.stopPropagation()}
                                >Click
                                </button></Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        }
    })
    return result2
}



function getnerateFileTre(parent, keyArray) {
    let paths = keyArray

    let result = [];
    let level = { result };

    paths.forEach(path => {
        path.split('/').reduce((r, name, i, a) => {
            if (!r[name] && name != '') {
                r[name] = { result: [] };
                r.result.push({ name, children: r[name].result, level: i, loc: a })
            }
            return r[name];
        }, level)
    })

    console.log(result)
    return result
}

function renderRes(data2, handleClose) {
    const result = data2 && data2.map((a, index) => {
        return <Accordion.Item eventKey={() => this.getKey()} >
            <Accordion.Header>{a.name}
                <button
                    type="button" onClick={(e) => e.stopPropagation()}
                >Click
                </button>
            </Accordion.Header>
            <Accordion.Body>
                {a.children && renderAcrodianItem(a.children)}
            </Accordion.Body>
        </Accordion.Item>
    })

    return (
        <div> <Button onClick={(e) => { e.preventDefault(); handleClose(); }}>User Rooms</Button>
            <Accordion alwaysOpen defaultActiveKey="0">
                {result}

            </Accordion >
        </div>
    )
}

function Dataroom({ }) {
    const [dataroomTree, setDTree] = useState([])
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)

    function handleClose() {
        setShow((a) => a = !a)
    }

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken()
            axios.post('', {}, {
                headers: {
                    'Authorization': accessToken.jwtToken
                }
            })
                .then(function (response) {
                    var result1 = response.data.Contents;
                    var keyArray = []
                    result1.map((r) => {
                        keyArray.push(r.Key)
                    })
                    //console.log(getnerateFileTre('', keyArray))
                    setDTree(getnerateFileTre('', keyArray))
                    setLoading(false)
                    return true;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        })
    }, [])

    if (loading) {
        return (
            <div>
                <Accordion alwaysOpen defaultActiveKey="0"><Spinner animation="border" variant="primary" />    </Accordion ></div>)
    }
    return <React.Fragment>
        {renderRes(dataroomTree, handleClose)}
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Search Users</Modal.Title>
            </Modal.Header>
            <Modal.Body><UserRoom /></Modal.Body>
        </Modal>
    </React.Fragment>
}

export default Dataroom;    