import { ConsoleLogger } from '@aws-amplify/core';
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from 'react';
import InvestorForm from '../forms/InvestorForm';

function FundTracker({ investors }) {

    const [show, setShow] = useState(false);
    const user = useState({})
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    const header = [

        'Name', 'Email', 'Date of First Contact', 'Contacted By', 'Status', 'Amount'

    ]
    const headerMap = header && header.map((a, index) => {
        return (
            <th>{a}</th>
        )
    })
    const tdsMap = investors && investors.map((a, index) => {
        return (
            <td>{a}</td>
        )
    })
    return (<div>
        <Button variant="primary" onClick={handleShow}>
            New Investor
        </Button>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>#</th>
                    {headerMap}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    {tdsMap}
                </tr>
            </tbody>
        </Table>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body><InvestorForm user={user} closeModal={setShow} /></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose (user)}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => handleClose (user)}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    </div>)
}

export default FundTracker;