import { ConsoleLogger } from '@aws-amplify/core';
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



class Opportunity extends Component {

    currencyFormat(num) {
        return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    state = {
        cashFlow: [
        ],
        formData: {
            investmentAmount: 50000,
            oppId: 101
        }
    }
    render() {

        let header = this.state.cashFlow.map(a => a.date);;
        let tds = this.state.cashFlow.map(a => this.currencyFormat(a.amount));

        const headerMap = header && header.map((a, index) => {
            return (
                <th>{a}</th>
            )
        })
        const tdsMap = tds && tds.map((a, index) => {
            return (
                <td>{a}</td>
            )
        })
        return (
            <div>
                <div className=''>
                    <div id='title-bar' className='d-flex flex-row mb-3 gap-3'>                        
                    </div>
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="Overview">
                           
                        </Tab>
                        <Tab eventKey="profile" title="Documents">
                        </Tab>
                        <Tab eventKey="inv" title="My Investment">

                        </Tab>
                    </Tabs>
                </div>
                <br/><br/><br/><br/><br/>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                {headerMap}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Cash Flow</td>
                                {tdsMap}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}

/**
 * 
 */
class InvCompanyProfile extends Component {


    state = {

        oppList: []
    }

    returnOppDetails() {
        return (
            <div></div>
        )
    }

    render() {
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Investment Opportunities</Breadcrumb.Item>
                </Breadcrumb>              
                <div>
                    <Opportunity />
                </div>
            </div>
        );
    }
}

export default InvCompanyProfile;