import React, { Component } from 'react'
import { Storage, Auth } from 'aws-amplify';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import './home.css'
import { ComponentPropsToStylePropsMap } from '@aws-amplify/ui-react';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink } from "react-router-dom";

Storage.configure({ level: 'private' });


class Home extends Component {


    state = {
        data: [],
        data2: [],
        uniqueEventId: 1,
        dirTree: {
            folders: [],
            files: [],
            path: '',
            folder: true
        }
    }

    async ionViewCanEnter() {
        try {
            await Auth.currentAuthenticatedUser().then(val => {
                console.log(val)
            });
            return true;
        } catch {
            return false;
        }
    }

    getnerateFileTre(parent, keyArray) {
        let paths = keyArray

        let result = [];
        let level = { result };

        paths.forEach(path => {
            path.split('/').reduce((r, name, i, a) => {
                if (!r[name] && name != '') {
                    r[name] = { result: [] };
                    r.result.push({ name, children: r[name].result, level: i, loc: a })
                }
                return r[name];
            }, level)
        })

        // console.log(result)
        return result
    }

    constructor(props) {
        super(props)
        this.ionViewCanEnter = this.ionViewCanEnter.bind(this);
        this.loadData = this.loadData.bind(this)
        this.getnerateFileTre = this.getnerateFileTre.bind(this)
        this.listFiles = this.listFiles.bind(this)
        this.ccLLL = this.ccLLL.bind(this)
        this.recur = this.recur.bind(this)
        this.renderAcrodianItem = this.renderAcrodianItem.bind(this)
        this.generateKeyPath = this.generateKeyPath.bind(this)
        //this.downloadBlob = this.downloadBlob.bind(this)
        // this.download = this.download.bind(this)
    }

    retriveFolderPaths(folder, dirs) {
        var res = []
        dirs.map((d) => {
            if (folder && d.key.indexOf('.') === -1) {
                //    console.log(d.key.split('dealdocuments')[1])
                //   console.log(d.key.split('dealdocuments')[1].split('/')[1])
                res.push({ key: d.key.split('dealdocuments')[1].split('/')[1] })
            } else if (!folder) {
                //    console.log(d.key.split('dealdocuments')[1])
                res.push({ key: d.key.split('dealdocuments')[1] })
            }
        })
        return res
    }

    buildTree(parts, tree) {
        var lastDir = 'root';
        var abs_path = '';
        parts.forEach(function (name) {
            // It's a directory
            if (name.indexOf('.') === -1) {
                lastDir = name;
                abs_path += lastDir + '/';

                if (!tree[name]) {
                    tree[name] = {
                        absolute_path: abs_path,
                        files: []
                    };
                }
            } else {
                tree[lastDir].files.push(name);
            }
        });
    }

    listFiles() {
        var dp = this.retriveFolderPaths(false, this.state.data)
        this.setState({
            loaded: true,
            data: dp,
        })
    }


    loadData() {
        this.setState({
            loaded: false
        });
        this.ionViewCanEnter().then(() => {
            console.log("fething data..")
            Storage.list('dealdocuments', { level: 'private' })
                .then(
                    result => {
                        console.log('8*******************')
                        console.log(result)
                        result = result.slice(1)
                        var keyArray = []
                        result.map((r) => {
                            keyArray.push(r.key)
                        })

                        this.setState({
                            loaded: true,
                            data: this.retriveFolderPaths(true, result),
                            keyArray: keyArray,
                            data2: this.getnerateFileTre('', keyArray)
                        })
                    }
                )
                .catch(err => console.log(err));
        }).catch(err => console.log(err));
    }

    componentDidMount() {
        this.loadData()
    }

    ccLLL(i, level) {
        //console.log('level:' + level + " ,index:" + i)
        var tmp = this.state.data2
        //console.log(tmp)
        this.recur(tmp, i, level)
    }

    recur(dat, i, level) {
        dat.map((t, index) => {
            // console.log('L:' + t.level + " ,I:" + index)
            if (t.level == level && i == index) {
                //   console.log('found')
                //  console.log(t)
            } else {
                this.recur(t.children, i, level)
            }
        })
    }


    downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    // usage
    async download(fileKey, filename) {
        const result = await Storage.get(fileKey, { download: true });
        this.downloadBlob(result.Body, filename);
    }


    generateKeyPath(loc) {
        var a = ''
        //  console.log('LOC' + loc.length)
        loc.map((y, index) => {
            a = a + y;
            if (index >= loc.length - 1) {

            } else {
                a = a + '/'
            }
            return a;
        })
        return a;
    }

    getKey(contentMap, i = 0) {
        if (i > 0) {

        } else {
            contentMap.map((y, index) => {
                i = i + 1
                y.index2 = i;
            })
        }
        return contentMap;
    }

    renderAcrodianItem(children) {
        var at = '/';
        const result2 = children.map((a, index) => {
            if (a.name.indexOf('.') == -1) {
                //   console.log('Rendering folder' + a.name)
                return <Accordion.Item className='folder' eventKey={() => this.getKey()} onClick={() => this.ccLLL(index, a.level)}>
                    <Accordion.Header>
                        <Container>
                            <Row>
                                <Col xs={1}><Image src='./folder.png'></Image></Col>
                                <Col>{a.name}</Col>
                            </Row>
                        </Container>
                    </Accordion.Header>
                    <Accordion.Body>
                        {this.renderAcrodianItem(a.children)}
                    </Accordion.Body>
                </Accordion.Item>
            } else {
                //    console.log('Rendering file' + a.name)
                //    console.log('File Paths = ' + this.generateKeyPath(a.loc))
                return <div onClick={() => this.download(this.generateKeyPath(a.loc), a.name)}>
                    <Card className='file' style={{ border: 'none', cursor: 'pointer' }}>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col xs={1}><Image src='./pdf.gif'></Image></Col>
                                    <Col>{a.name}</Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            }
        })
        return result2
    }

    render() {
        const { data, data2 } = this.state;
        const result = data2 && data2[0] && data2[0].children && data2[0].children.map((a, index) => {
            return <Accordion.Item eventKey={() => this.getKey()} >
                <Accordion.Header> <Container>
                    <Row>
                        <Col xs={1}><Image src='./folder.png'></Image></Col>
                        <Col>{a.name}</Col>
                    </Row>
                </Container></Accordion.Header>
                <Accordion.Body>
                    {this.renderAcrodianItem(a.children)}
                </Accordion.Body>
            </Accordion.Item>
        })
        return (
            <div>
                {this.state.loaded ? <Accordion alwaysOpen defaultActiveKey="0">
                    {result}
                </Accordion > : <Row><Col><Spinner animation="border" variant="primary" /></Col></Row>}
            </div>
        )
    }
}

export default Home