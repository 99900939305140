import { Component } from "react";
import './footer.css'

class Footer extends Component {

    render() {
        return (
            <footer id="footer" class="footer-1" >
                <div class="footer-copyright">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <p>Madison Lake Capital LLC © 2022. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>)
    }
}

export default Footer;