import { ConsoleLogger } from '@aws-amplify/core';
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import { useState, useEffect } from 'react';
import InvestorForm from '../forms/InvestorForm';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ListGroup } from 'react-bootstrap';
import { Amplify, Storage, Auth } from 'aws-amplify';
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';


export const Child = () => {

    const [first_name, setFnam] = useState()
    const [last_name, setLnam] = useState()
    const [email, setEmail] = useState()


    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            axios.post('https://4evfrgphxk.execute-api.us-east-1.amazonaws.com/test/user', {}, {
                headers: {
                    'Authorization': accessToken.jwtToken
                }
            })
                .then(function (response) {
                    setFnam( response.data.Items[0].first_name);
                    setLnam( response.data.Items[0].last_name);
                    setEmail( response.data.Items[0].email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        })

    })    

    if (!first_name) return <ProgressBar animated now={100} />;

    return (
        <ListGroup className="list-group-flush">
            <ListGroup.Item>{first_name}</ListGroup.Item>
            <ListGroup.Item>{last_name}</ListGroup.Item>
            <ListGroup.Item>{email}</ListGroup.Item>
        </ListGroup>
    )
}

function InvestorProfile({ investors }) {


    return (<div>
        <Card>
            <Card.Header as="h5">My Profile</Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        <Col><Child /></Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}

export default InvestorProfile;