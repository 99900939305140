import { ConsoleLogger } from '@aws-amplify/core';
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';

function Deals({ }) {

    let navigate = useNavigate();

    let data = {

        oppList: []
    }

    const result = data.oppList && data.oppList.map((a, index) => {
        return <Card style={{ width: '18rem', margin: '5px' }}>
            <Card.Img variant="top" src="holder.js/100px180" />
            <Card.Body>
                <Card.Title>{a.title}</Card.Title>
                <Card.Text>
                    {a.text}
                </Card.Text>
                <Button variant="primary" onClick={() => navigate("invprofile/"+a.id+"/read")}>More Info</Button>
            </Card.Body>
        </Card>
    })

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Deals</Breadcrumb.Item>
            </Breadcrumb>
            <div class="d-flex align-content-start flex-wrap ms-auto">
                {result}
            </div>
        </div>
    );


}
export default Deals;