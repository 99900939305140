import { ConsoleLogger } from '@aws-amplify/core';
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



class Opportunity extends Component {

    currencyFormat(num) {
        return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    state = {
        cashFlow: [
            
        ],
        formData: {
            investmentAmount: 50000,
            oppId: 101
        }
    }
    render() {

        let header = this.state.cashFlow.map(a => a.date);;
        let tds = this.state.cashFlow.map(a => this.currencyFormat(a.amount));

        const headerMap = header && header.map((a, index) => {
            return (
                <th>{a}</th>
            )
        })
        const tdsMap = tds && tds.map((a, index) => {
            return (
                <td>{a}</td>
            )
        })
        return (
            <div>
                <div className=''>
                    <div id='title-bar' className='d-flex flex-row mb-3 gap-3'>
                        <div className='p1 w-25'>
                            <img src="https://images.huffingtonpost.com/2016-01-04-1451914003-4643351-Dollarphotoclub_86438962.jpg" class="img-thumbnail" ></img>
                        </div>
                        <div className='p2 w-75'>                           
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="Overview">
                            
                        </Tab>
                        <Tab eventKey="profile" title="Documents">
                        </Tab>
                        <Tab eventKey="inv" title="My Investment">

                        </Tab>
                    </Tabs>
                </div>
                <br/><br/><br/><br/><br/>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                {headerMap}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Cash Flow</td>
                                {tdsMap}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}

/**
 * 
 */
class Opportunities extends Component {


    state = {

        oppList: []
    }

    returnOppDetails() {
        return (
            <div></div>
        )
    }

    render() {
        const result = this.state.oppList && this.state.oppList.map((a, index) => {
            return <Card style={{ width: '18rem', margin: '5px' }}>
                <Card.Img variant="top" src="holder.js/100px180" />
                <Card.Body>
                    <Card.Title>{a.title}</Card.Title>
                    <Card.Text>
                        {a.text}
                    </Card.Text>
                    <Button variant="primary">More Info</Button>
                </Card.Body>
            </Card>
        })
        console.log(result)
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Investment Opportunities</Breadcrumb.Item>
                </Breadcrumb>
                <div class="d-flex align-content-start flex-wrap ms-auto">
                    {result}
                </div>
                <div>
                    <Opportunity />
                </div>
            </div>
        );
    }
}

export default Opportunities;